import React from 'react';
import { get, truncate } from 'lodash';
import {
  ItemsSlider,
  Title,
  Card,
  Spacer,
  Paragraph,
  Link,
} from '../common/components';
import { Row, Col } from 'antd';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { theme } from '../common/theme';

interface Props {
  data: any;
}
const ContentTitle = styled(Title)`
  margin-bottom: 0em !important;
  ${breakpoint('md')`
      margin-bottom: 0.5em !important;
    `}
`;
const ContentDescription = styled(Paragraph)`
  margin-bottom: 0 !important;

  & > span > p:last-child {
    margin-bottom: 0;
  }
  & > span > h4 {
    color: ${props => props.theme.color.text.main}!important;
    margin-top: 0em !important;
    ${breakpoint('md')`
      margin-top: 0.5em !important;
    `}
  }
`;
const ContentCard = styled(Row)`
  /* ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    height: 250px;
  `} */
  ${props =>
    props.layout === 'left' &&
    css`
      flex-direction: row-reverse !important;
    `}
  margin:0 10px;
  /* height: 180px; */
  
  ${breakpoint('md')`
    margin-left: 10px;
    margin-right: 10px;
    /* height: 300px; */
    min-height:300px;
    max-width: 1000px;
  `}
  
`;

const CardContentContainer = styled(Card.Content)`
  margin-top: 1rem;

  ${breakpoint('md')`
    margin-top:0; 
    ${props =>
      props.layout &&
      props.layout === 'right' &&
      css`
        margin-left: 6vw;
        margin-right: 1vw;
      `}
    ${props =>
      props.layout &&
      props.layout === 'left' &&
      css`
        margin-right: 6vw;
        margin-left: 1vw;
      `}
  `}
`;

const ReadMoreText = styled(Paragraph)`
  text-align: left;
`;

const SpacerWithStyled = styled(Spacer)`
  /* height: 100px; */
  margin: 1em 0;
  text-align: left;
`;
const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  /* margin-top: 2rem;
  margin-bottom: 1rem; */
`;
const InnerContainer = styled(Row)`
  width: 100%;

  max-width: 1000px;
  ${breakpoint('md')`
    max-width: 1100px;
  `}
`;

const SecondContentTitle = styled(Title)`
  padding-top: 15px;
`;

const ContentItem = ({
  title,
  image,
  link,
  linkText,
  layout,
  description,
  htmlDescription,
  secondContentTitle,
  descriptionHtml,
}) => (
  <ContentCard type="flex" color="transparent" layout={layout}>
    <Col xs={24} md={0}>
      {title && (
        <ContentTitle color={theme.color.text.main}>{title}</ContentTitle>
      )}
    </Col>
    <Col xs={24} md={0}>
      <SpacerWithStyled color={theme.color.secondary.main} />
    </Col>
    {image && (
      <Card.CoverImageContainer xs={24} md={12} style={{ height: 300 }}>
        <Card.CoverImage
          fluid={get(image, 'localFile.childImageSharp.fluid')}
          alt={get(image, 'alt')}
          imgStyle={{ 'object-position': 'top center' }}
          showAnimation
        />
      </Card.CoverImageContainer>
    )}
    <Card.ContentContainer xs={24} md={12}>
      <CardContentContainer type="flex" justify="start" layout={layout}>
        <Col xs={0} md={24}>
          {title && (
            <ContentTitle color={theme.color.text.main}>{title}</ContentTitle>
          )}
        </Col>
        <Col xs={0} md={24}>
          <SpacerWithStyled color={theme.color.secondary.main} />
        </Col>
        <Col xs={24}>
          <SecondContentTitle level={3}>
            {secondContentTitle}
          </SecondContentTitle>
          <ContentDescription />
        </Col>
        <Col xs={24}>
          <ContentDescription small>
            {htmlDescription ? (
              <span dangerouslySetInnerHTML={{ __html: htmlDescription }} />
            ) : (
              description
            )}
          </ContentDescription>
        </Col>
        {link && linkText && (
          <Col style={{ marginTop: title ? 15 : 0 }} xs={24}>
            <Link to={link} target="bank">
              <Card.LinkText color={theme.color.text.main}>
                <ReadMoreText small>{linkText}</ReadMoreText>
              </Card.LinkText>
            </Link>
          </Col>
        )}
      </CardContentContainer>
    </Card.ContentContainer>
  </ContentCard>
);
const CreateItems = (items: any, title, layout) => {
  const awardsItem = items.map((item, i) => {
    const id = get(item, 'id', i);

    const articlePdfLink = get(
      item,
      'article.document[0].data.article_pdf.url',
      null
    );

    const articleLink = get(item, 'article.document[0].data.url');
    const content = truncate(
      get(item, 'article.document[0].data.content.text', ''),
      { length: 200 }
    );
    const contentHtml = get(
      item,
      'article.document[0].data.contentExtract.html',
      ''
    );
    //console.log('>> ', title, contentHtml);
    return (
      <ContentItem
        key={id}
        title={title}
        secondContentTitle={get(
          item,
          'article.document[0].data.title',
          ''
        ).toUpperCase()}
        image={get(item, 'article.document[0].data.preview_image')}
        link={articlePdfLink ? articlePdfLink : articleLink}
        linkText={get(item, 'article.document[0].data.read_more_text', null)}
        layout={layout}
        description={truncate(
          get(item, 'article.document[0].data.content.text', ''),
          { length: 200 }
        )}
        htmlDescription={get(
          item,
          'article.document[0].data.content_extract.html',
          null
        )}
      />
    );
  });
  return awardsItem;
};
class LocalAttractionContentSliders extends React.Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: false,
    };
  }
  render() {
    const { data } = this.props;
    const { items = [] } = data;
    const title = get(data, 'primary.title', '').toUpperCase();
    const type = get(data, 'primary.type', '');
    const layout =
      type === 'Image on the right, Description on the left' ? 'left' : 'right';

    const settings = {
      slidesToShow: 1,
      autoplay: this.state.autoplay,
      speed: 3000,
      rtl: false,
      autoplaySpeed: 5000,
      cssEase: 'linear',
      responsive: [],
    };
    //console.log('>>> setting', settings.autoplay);
    return (
      <Container>
        {items.length > 0 && (
          <InnerContainer
            onMouseEnter={() => this.setState({ autoplay: true })}
            onMouseLeave={() => this.setState({ autoplay: false })}
          >
            <Col span={24}>
              <ItemsSlider
                sliderItems={CreateItems(items, title, layout)}
                settings={settings}
              />
            </Col>
          </InnerContainer>
        )}
      </Container>
    );
  }
}

export default LocalAttractionContentSliders;
