import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero, PageIntroduction } from '../common/components';

import LocalAttractionContentSliders from './LocalAttractionContentSliders';

interface Props {
  data: any;
  location: any;
}

class LocalAttraction extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body) &&
          body.map(slice => {
            switch (slice.__typename) {
              case 'PrismicLocalAttractionBodyHero':
                return (
                  <PageHero key={slice.id + slice.__typename} data={slice} />
                );
              case 'PrismicLocalAttractionBodyIntroduction':
                return (
                  <PageIntroduction
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );
              case 'PrismicLocalAttractionBodyContentSliders':
                return (
                  <LocalAttractionContentSliders
                    key={slice.id + slice.__typename}
                    data={slice}
                  />
                );

              default:
                return null;
            }
          })}
        <div style={{ width: '100%', height: '3rem' }} />
      </Layout>
    );
  }
}

export default LocalAttraction;

// Query metadata for the homepage
export const query = graphql`
  query LocalAttractionQuery($slug: String!) {
    page: prismicLocalAttraction(id: { eq: $slug }) {
      ...LocalAttraction
    }
  }
`;
